<template>
  <v-app style="margin-top:  75px;">
    <v-container>
      <v-layout align-center justify-center>
        <v-flex xs12>
          <v-card>
            <v-img src="../../assets/img/xgm/home0.png" aspect-ratio="2.5" contain style="margin-top: 10px;"></v-img>
            <v-card-title class="headline" style="margin-left: 30%;">
              欢迎来到希革马AI后台管理系统
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'WelcomePage',
};
</script>

<style scoped></style>